<template>
  <div class="layout-wrap">
    <div class="top-bar flex-center-center">
      <img class="top-bar-logo m-r-80" src="@/assets/icon/logo.png" alt="" />
      <CustomSelect
        v-model="level"
        class-wrap="m-r-40"
        :icon="true"
        :list="levelList"
        @select="handleSelectLevel"
      ></CustomSelect>
      <CustomSelect
        v-model="word"
        class-wrap="m-r-40"
        :icon="true"
        :list="wordList"
        class-current="bgGreenYellow"
        class-item="bgGreenYellow"
        type="Term "
        @select="handleSelectWord"
      ></CustomSelect>
      <CustomSelect
        v-model="sentence"
        class-wrap="m-r-80"
        :icon="true"
        :list="sentenceList"
        class-current="bgBlueGreen"
        class-item="bgBlueGreen"
        @select="getCourseList"
      ></CustomSelect>
      <div class="index-list flex-center-center">
        <div
          v-for="item in Object.keys(courseData)"
          :key="item"
          class="bar-item flex-center-center"
        >
          <div
            class="index-item"
            :class="activeNum == item ? 'active' : ''"
            @click="handleSelectNum(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- <el-button
        type="danger"
        class="logout-button"
        @click.stop.prevent="logout"
      >
        退出
      </el-button> -->
    </div>
    <div class="bottom-content flex-start-center">
      <div class="left-info flex-start-center">
        <div class="left-head">
          <img src="@/assets/images/head.png" class="account-head" alt="" />
        </div>
        <div class="account-name">{{ teacherName }}</div>
        <el-button
          type="danger"
          class="account-logout"
          @click.stop.prevent="logout"
        >
          退出
        </el-button>
        <!-- <div class="plan bgBlue">Syllabus</div> -->
        <!-- <div class="plan bgBlue">Lesson Plan</div> -->
        <img class="read-word" src="@/assets/images/title.png" alt="" />
      </div>
      <div class="center-box">
        <div class="content">
          <img
            class="icon-amplify"
            src="@/assets/icon/icon-amplify.png"
            alt=""
            @click="() => toggleFullScreen()"
          />
          <div
            v-if="pageType === 'json' && currentStatus === 1"
            ref="fullContent"
            style="width: 100%; height: calc(100% - 110px); background: #ffffff"
          >
            <div
              v-for="(page, ind) in pageList"
              :key="ind"
              class="quark-page"
              :style="getCommonStyle(page.commonStyle)"
            >
              <div
                v-if="pageList.length > 1"
                class="prev-next flex-between-center"
              >
                <img
                  src="https://fande-pro.obs.cn-north-4.myhuaweicloud.com:443/webactive/psd-batch/1694071001231/psd-resource-73.jpg"
                  class="left-button button"
                  @click="handleSelectPage('pre')"
                />
                <img
                  src="https://fande-pro.obs.cn-north-4.myhuaweicloud.com:443/webactive/psd-batch/1694071001231/psd-resource-72.jpg"
                  class="right-button button"
                  @click="handleSelectPage('next')"
                />
              </div>
              <transition name="slide-fade">
                <div v-if="currentPageIndex === ind" class="quark-page-wrapper">
                  <componentsTemplate
                    v-for="(item, index) in page.elements"
                    :key="index"
                    :element="item"
                    :style="getCommonStyle(item.commonStyle)"
                    @handleElementClick="handleElementClick"
                  />
                </div>
              </transition>
            </div>
          </div>
          <div
            v-else-if="pageType === 'html' && currentStatus === 1"
            class="html-content"
          >
            <!-- <div v-if="showPreviewList" class="slid-box">
              <div class="slid">
                <div
                  v-for="(item, index) in previewList"
                  :key="item"
                  class="slid-item"
                  @click="goContentPage(index)"
                >
                  {{ item }}
                  <img :src="`${previewhost}/slide/${item}.png`" alt="" />
                </div>
              </div>
            </div> -->
            <div ref="fullContent" class="iframe-content">
              <ImgDrawInline
                v-if="showCanvasInline"
                :tool-type="toolType"
                @close="closeInlineCanvas"
              />
              <img
                class="icon-amplify"
                src="@/assets/icon/icon-amplify.png"
                alt=""
                @click="() => toggleFullScreen()"
              />
              <div class="iframe-operate">
                <img
                  src="@/assets/images/pre.png"
                  class="icon-pre"
                  @click="preSlide('htmlFrame')"
                />
                <img
                  src="@/assets/images/next.png"
                  class="icon-next"
                  @click="nextSlide('htmlFrame')"
                />
                <div v-if="isFullScreenStatus" class="full-screen-right-menu">
                  <div
                    v-show="!isShowMenu"
                    class="el-icon-s-tools arrow"
                    style="
                      position: absolute;
                      top: 288px;
                      right: 30px;
                      width: 32px;
                      height: 32px;
                      line-height: 32px;
                      font-size: 20px;
                      color: rgb(30, 144, 255);
                      border-radius: 50%;
                      text-align: center;
                      border: 1px solid rgb(30, 144, 255);
                      background-color: #fff;
                    "
                    @click="handleInlineMenuClick()"
                  ></div>
                </div>
              </div>
              <iframe
                id="htmlFrame"
                :src="pageList[0]"
                class="frame-class"
              ></iframe>
            </div>
          </div>
          <div v-else ref="fullContent" class="html-content">
            <img
              src="https://static-oss.abiefamily.com/static/icon/lock.png"
              style="width: 100%; height: 100%"
            />
          </div>
          <div class="bottom-bar flex-center-center">
            <div
              v-for="(item, index) in bottomBar"
              :key="index"
              class="bar-item flex-center-center"
            >
              <div @click="handleSelectedTemplate(item)">
                {{ item.processName }}
              </div>
            </div>
          </div>
        </div>
        <div class="right-menu">
        <!--<div  class="mlw" @click="handlemlwUrl()">My Little World</div>-->
          <div class="menu-title">Menu</div>
          <div class="menu-list flex-start-center"> 
           <div  style="margin-top:30px;font-size:18px; font-weight:600"  @click="handlemlwUrl()">MLW</div>         
            <div v-for="item in menuList" :key="item.index" class="menu-item">
              <i
                :class="['iconfont', item.icon]"
                @click="handleMenuClick(item)"
              />
            </div>           
          </div>
        </div>
      </div>
    </div>
    <ImgDraw v-if="showCanvas" :tool-type="toolType" @close="closeCanvas" />
  </div>
</template>

<script>
import componentsTemplate from "./components-template";
import { getCourse, getSchedule } from "@/api";
import axios from "axios";
import "core-js/actual/array/group";
import { fontFamilyData } from "../../common/js/fontFamily";
import ImgDraw from "@/components/canvas/imgDraw.vue";
import ImgDrawInline from "@/components/canvas/imgDrawInline.vue";
import html2canvas from "html2canvas";
import screenfull from "screenfull";
import { setToken } from "@/utils/auth";
import Cookies from "js-cookie";

export default {
  name: "Layout",
  components: {
    componentsTemplate,
    ImgDraw,
    ImgDrawInline,
  },
  data() {
    return {
      coordinationData: [],
      courseData: {},
      levelList: ["Level 1"],
      level: "Level 1",
      wordList: [1],
      word: "1",
      sentenceList: ["          "],
      sentence: "        ",
      menuList: [
        {
          icon: "icon-qianbi",
          name: "画笔",
          toolType: 1,
        },
        {
          icon: "icon-huabigongju-zhixian",
          name: "直线",
          toolType: 2,
        },
        {
          icon: "icon-huabigongju-tuoyuan",
          name: "圆形",
          toolType: 3,
        },
        {
          icon: "icon-huabigongju-juxing",
          name: "矩形",
          toolType: 5,
        },
        {
          icon: "icon-rubber-full",
          name: "橡皮",
          toolType: 7,
        },
        {
          icon: "icon-huabigongju-wenben",
          name: "文字",
          toolType: 8,
        },
      ],
      toolType: 1,
      showCanvas: false,
      bottomBar: [],
      activeNum: 1,
      pageType: "json",
      pageList: [],
      currentPageIndex: 0,
      // text
      text: {
        version: "1.0.0",
        commandId: 0,
        commandType: "",
        description: "",
        data: {
          slide: 0,
          step: 0,
          clickId: "",
        },
      },
      previewList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      showPreviewList: true,
      currentStatus: 1,
      iframeUrl: "",
      iframeCanvas: "",
      teacherName: window.localStorage.getItem("teacherName") || "",
      showCanvasInline: false,
      previewhost: "",
      isFullScreenStatus: false,
      isShowMenu: false,
    };
  },
  watch: {
    level(val) {},
    word(val) {},
    sentence(val) {
      this.getCourseList();
    },
  },
  created() {
       const Params = {
        page: 1,
        pageSize: 20,
        courseWareQuery: true,
      };      
   
      
    getSchedule(Params)
      .then((res) => {
        console.log(res);
        this.coordinationData = res.result.records;
        this.levelList = this.coordinationData.map((item) => item.intro);
        this.level = this.levelList[0];
        this.wordList = this.coordinationData[0].terms;
        this.word = this.wordList[0];
        this.sentenceList = this.coordinationData[0].stageBookMap[
          this.word
        ].map((item) => item.bookName);
        this.sentence = this.sentenceList[0];
        this.getCourseList();
      })
      .catch((e) => {
        if (e.status === 401) {
          this.$router.replace({
            path: "/login",
          });
        }
      });
  },
  methods: {
    getCourseList() {
      const curLevel = this.coordinationData.find(
        (item) => item.intro === this.level
      );
      const bookId = curLevel.stageBookMap[this.word].find(
        (item) => item.bookName === this.sentence
      ).bookId;
      const queryParams = {
        page: 1,
        pageSize: 1000,
        planId: curLevel.id,
        stage: this.word,
        bookId,
      };
      getCourse(queryParams)
        .then((res) => {
          this.courseData = res.result.records.group(({ num }) => num);
          this.activeNum = Object.keys(this.courseData)[0];
          this.bottomBar = this.courseData[this.activeNum];
          if (
            this.bottomBar &&
            this.bottomBar.length > 0 &&
            this.bottomBar?.[0]?.resourceJsonUrl &&
            this.bottomBar?.[0]?.resourceJsonUrl?.length > 0 &&
            this.bottomBar?.[0]?.resourceJsonUrl?.[0]?.url
          ) {
            this.readContent(this.bottomBar?.[0]?.resourceJsonUrl?.[0]);
            this.currentStatus = this.bottomBar?.[0]?.status;
          } else {
            this.pageList = [];
            this.pageType = "";
            return {};
          }
        })
        .catch((e) => {
          if (e.status === 401) {
            this.$router.replace({
              path: "/login",
            });
          }
        });
    },
    handleSelectNum(item) {
      this.activeNum = item;
      this.bottomBar = this.courseData[item];
      this.handleSelectedTemplate(this.bottomBar[0]);
    },
    handleSelectedTemplate(course) {
      this.currentStatus = course?.status;
      this.readContent(course?.resourceJsonUrl?.[0]);
    },
    async readContent(content) {
      if (content?.type === 1) {
        this.pageType = "json";
        const res = await axios.get(content.url);
        this.currentPageIndex = 0;
        this.pageList = JSON.parse(res.pages);
        this.getFontFamilySet(this.pageList);
      } else if (content?.type === 2) {
        this.pageType = "html";
        this.pageList = [content.url];
        this.iframeUrl = content.url;
        this.getCorrectImg();
      } else {
        this.pageType = "";
      }
    },
    handleSelectPage(operation) {
      if (operation === "pre") {
        if (this.currentPageIndex > 0) {
          --this.currentPageIndex;
        }
      } else if (operation === "next") {
        if (this.pageList.length > this.currentPageIndex + 1) {
          ++this.currentPageIndex;
        }
      }
    },
    handlemlwUrl(){
      const token = Cookies.get("token");
      const name = window.localStorage.getItem("teacherName");
      const userId = window.localStorage.getItem("userId");
      const timestamp = new Date().getTime();
      const url = `https://course.abiereading.com/abiereadingcourse/home.html?key=${timestamp}`;
      console.log(url);
     if(token)
     {              
        window.location.href =url;       
     }    
    
    },
    handleElementClick() {},
    // 生成页面和组件基础属性
    getCommonStyle(styleObj) {
      const needUnitStr = [
        "width",
        "height",
        "top",
        "left",
        "paddingTop",
        "paddingLeft",
        "paddingRight",
        "paddingBottom",
        "marginTop",
        "marginLeft",
        "marginRight",
        "marginBottom",
        "borderWidth",
        "fontSize",
        "borderRadius",
        "letterSpacing",
      ];
      const style = {};

      for (const key in styleObj) {
        if (needUnitStr.includes(key)) {
          style[key] = styleObj[key] + "px";
        } else {
          style[key] = styleObj[key];
        }
      }

      if (styleObj?.animations && styleObj.animations.length > 0) {
        const animation = styleObj.animations[0];
        style.animation = `${animation.value} ${animation.duration}s ${
          animation.timing
        } ${animation.delay == null ? 0 : animation.delay}s ${
          animation.interationCount
        } ${animation.direction} ${animation.fillMode} ${animation.running}`;
      }

      style.transform = `rotate(${style.rotate}deg)`;
      style.backgroundImage = style?.backgroundImage
        ? `url(${style.backgroundImage})`
        : "";
      style.maxWidth = "100%";
      style.maxHeight = "100%";
      return style;
    },
    // 下载字体
    getFontFamilySet(pages) {
      let fontFamilySet = [];
      pages.forEach((item) => {
        if (item.elements.length > 0) {
          item.elements.forEach((item) => {
            if (item.elName === "qk-text" && item.commonStyle?.fontFamily) {
              fontFamilySet.push(item.commonStyle.fontFamily);
            }
          });
        }
      });
      fontFamilySet = [...new Set(fontFamilySet)];

      fontFamilySet.forEach((item) => {
        const style = document.createElement("style");
        let styleText = "";
        style.type = "text/css";
        styleText += ` @font-face { font-family:${item}; src:url('${
          fontFamilyData.filter((ite) => ite.name === item)[0].url
        }')};`;
        style.innerText = styleText;
        document.getElementsByTagName("head")[0].appendChild(style);
      });
    },
    handleInlineMenuClick() {
      this.showCanvasInline = true;
      this.isShowMenu = true;

      this.toolType = 1;
      // 把iframe画成图片
      const iframeHtml = this.$refs.iframe.contentWindow; // 获取iframe内容
      const iframeBody = iframeHtml.document.body;
      this.isFullScreenStatus = true;
    },
    handleMenuClick(item) {
      const _this = this;
      _this.showCanvas = true;
      _this.toolType = item.toolType;
      // 把iframe画成图片
      const iframeHtml = _this.$refs.iframe.contentWindow; // 获取iframe内容
      const iframeBody = iframeHtml.document.body;
    },
    closeInlineCanvas() {
      this.showCanvasInline = false;
      this.isShowMenu = false;
    },
    closeCanvas() {
      this.showCanvas = false;
      this.isShowMenu = false;
    },
    postMessage(id) {
      const iframe = document.getElementById(id);
      iframe.contentWindow.postMessage(JSON.stringify(this.text), "*");
    },
    play(id) {
      this.text.commandId = 3;
      this.text.commandType = "play";
      this.postMessage(id);
    },
    pause(id) {
      this.text.commandType = "pause";
      this.postMessage(id);
    },
    gotoSlide(id, slide) {
      this.text.commandId = 2;
      this.text.commandType = "gotoSlide";
      this.text.data.slide = slide;
      this.postMessage(id);
    },
    nextSlide(id) {
      this.text.commandId = 5;
      this.text.commandType = "next";
      this.postMessage(id);
    },
    preSlide(id) {
      this.text.commandId = 5;
      this.text.commandType = "pre";
      this.postMessage(id);
    },
    gotoStep(id, slide, step) {
      this.text.commandId = 10;
      this.text.data.slide = slide;
      this.text.data.step = step;
      this.text.commandType = "onStepChange";
      this.postMessage(id);
    },
    goContentPage(index) {
      this.gotoStep("htmlFrame", index, 0);
    },
    // 轮循可用预览图
    async getCorrectImg() {
      this.previewhost = this.iframeUrl.replace(/\/index\.html$/, "");
      const tempImgList = [];
      for (let i = 1; i < 50; i++) {
        try {
          await axios.get(`${this.previewhost}/slide/${i}.png`);
          tempImgList.push(i);
        } catch (error) {
          break;
        }
      }
      this.previewList = tempImgList.sort((a, b) => a - b);
    },
    getHost(url) {
      const hostReg = /:\/\/(.[^/:]+)/;
      const match = url.match(hostReg);
      return match[1];
    },
    toggleFullScreen() {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.fullContent);
      }

      if (!screenfull.isFullscreen) {
        this.isFullScreenStatus = true;
        // this.handleInlineMenuClick();
      } else {
        this.isFullScreenStatus = false;
        this.closeInlineCanvas();
      }
    },
    logout() {
      setToken("");
      this.$router.replace("/login");
    },
    handleSelectLevel() {
      const cur = this.coordinationData.find(
        (item) => item.intro === this.level
      );
      this.wordList = cur.terms;
      this.word = this.wordList[0];
      this.sentenceList = cur.stageBookMap[cur.terms[0]].map(
        (item) => item.bookName
      );
      this.sentence = this.sentenceList[0];
      this.getCourseList(); 
    },
    handleSelectWord(val) {
      const curIndex = this.coordinationData.findIndex(
        (item) => item.intro === this.level
      );
      this.sentenceList = this.coordinationData[curIndex].stageBookMap[val].map(
        (item) => item.bookName
      );
      this.sentence = this.sentenceList[0];
      this.getCourseList();
    },
  },
};
</script>

<style scoped lang="scss">
.layout-wrap {
  .bgBlue {
    @include bgColor(left, center, 120px, #28a7e1, #1f80c4);
  }

  .top-bar {
    height: 140px;

    .top-bar-logo {
      width: 372px;
    }
    .index-list {
      .index-item {
        width: 68px;
        height: 68px;
        border: 4px solid #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 68px;
        background-color: #b5b6b5;
        margin-right: 20px;
        color: #fff;
        font-size: 40px;
        font-weight: bold;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-left: 10px;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          background-image: url("@/assets/images/bg-active.png");
        }
      }
    }
    .logout-button {
      border-radius: 10px;
      position: absolute;
      right: 50px;
      font-size: 24px;
    }
  }
  .bottom-content {
    height: calc(100% - 156px);
  }

  .left-info {
    width: 219px;
    padding-top: 16px;
    height: 100%;
    flex-direction: column;

    .left-head {
      width: 120px;
      height: 120px;
      margin-bottom: 10px;
      border-radius: 50%;
      padding: 2px;
      background: url("@/assets/images/bg-head.png") no-repeat;
      background-size: 100% 100%;

      .account-head {
        width: calc(100% - 4px);
      }
    }

    .account-name {
      color: #fff;
      font-size: 38px;
      font-weight: bold;
    }
    .account-logout {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    .plan {
      width: calc(100% - 20px);
      min-width: 206px;
      margin-bottom: 40px;
      height: 56px;
      text-align: center;
      line-height: 56px;
      border-radius: 56px;
      font-size: 30px;
      font-weight: bold;
      color: #fff;
    }

    .read-word {
      width: 40px;
    }
  }

  .center-box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .content {
      width: calc(100% - 250px);
      height: 940px;
      position: relative;
      background: #fff;
      .iframe-content {
        width: 100%;
        height: 100%;
        position: relative;
        .icon-pre {
          position: absolute;
          bottom: 35px;
          left: 75px;
        }
        .icon-next {
          position: absolute;
          bottom: 35px;
          right: 75px;
        }
      }

      .frame-class {
        width: calc(100% - 36px);
        height: calc(100% - 36px);
        margin: 0px auto;
        padding: 18px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        background-color: rgb(255, 245, 226);
        border: none !important;
      }

      .icon-amplify {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        z-index: 99999;
      }
      .prev-next {
        position: absolute;
        z-index: 99;
        bottom: 80px;
        left: 76px;
        right: 76px;
        .button {
          width: 75px;
          height: 75px;
        }
      }
      .quark-page {
        position: absolute !important;
        width: 1475px !important;
        height: 831px !important;
        top: 0px !important;
        left: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
      }
      .html-content {
        width: 100%;
        height: calc(100% - 110px);
        display: flex;
        flex-direction: row;
        .slid-box {
          width: 142px;
          background-color: #fff;
          border-right: 24px solid #fff5e2;
          height: 100%;
          .slid {
            overflow-x: hidden;
            padding: 20px;
            overflow-y: scroll;
            height: calc(100% - 40px);
            cursor: pointer;
            width: 142px;
            background-color: #fff;
            border-right: 24px solid #fff5e2;
            .slid-item {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              padding: 5px;
              color: #666;
            }
            img {
              width: 114px;
              margin-left: 12px;
            }
          }
        }
      }
    }

    .bottom-bar {
      width: 100%;
      height: 110px;
      background: url("@/assets/images/bg-model.png") no-repeat;
      background-size: 100% 100%;

      .bar-item {
        width: calc(16% - 64px);
        height: 84px;
        border-right: 4px solid #fff;
        display: inline-flex;
        font-size: 32px;
        font-weight: bolder;
        color: #fff;
        text-align: center;
        padding: 0 30px;
        cursor: pointer;

        &:first-child {
          width: calc(18% - 4px);
          padding: 0;
        }

        &:last-child {
          width: calc(18% - 60px);
          border: none;
        }
      }
    }
  }

  .right-menu {
    width: 226px;
    margin-top: -150px;
    height: 100%;
    color: #fff;
    .menu-title {
      position: relative;
      height: 70px;
      line-height: 40px;
      text-align: center;
      font-size: 36px;

      &::before {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -30px;
        width: 60px;
        height: 4px;
        background: #fff;
      }
    }
    .mlw{
       margin-left:10px;
       padding:25px 5px;       
        cursor: pointer;
        font-size: 25px;
       
    }
    .menu-list {
      flex-direction: column;

      .menu-item {
        margin-top: 40px;
        cursor: pointer;
        .iconfont {
          font-size: 40px;
        }
        .email,
        .phone,
        .pc {
          width: 58px;
        }

        .inter {
          width: 60px;
        }

        .setting {
          width: 50px;
        }

        .message {
          width: 42px;
        }
      }
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  .iframe-operate {
    .full-screen-right-menu {
      width: 150px;
      margin-top: 80px;
      color: #fff;
      position: absolute;
      top: 0px;
      right: 0px;
      .menu-title {
        position: relative;
        height: 70px;
        line-height: 40px;
        text-align: center;
        font-size: 36px;

        &::before {
          content: " ";
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -30px;
          width: 60px;
          height: 4px;
          background: #fff;
        }
      }

      .menu-list {
        flex-direction: column;

        .menu-item {
          margin-top: 40px;
          cursor: pointer;
          .iconfont {
            font-size: 40px;
          }
          .email,
          .phone,
          .pc {
            width: 58px;
          }

          .inter {
            width: 60px;
          }

          .setting {
            width: 50px;
          }

          .message {
            width: 42px;
          }
        }
      }
    }
  }
}
</style>
